import { Col, Row } from "react-bootstrap";
import styled from "../assets/style/Footer.module.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { ContactInfo } from "../data/constants";
import { useTranslation } from "react-i18next";
import { strimAllSpace } from "../utils/stringUtil";

import useAppStore from "../store/appStore";

const Footer = () => {
  const { t } = useTranslation();
  const currentPath = useAppStore((state) => state.currentPath);
  let isContactPage = false;
  if (currentPath?.includes("contactUs")) {
    isContactPage = true;
  }
  const companyAddess = (
    <Col xs={12} sm={6} md={6} lg={3}>
      <h3>{t("content.footer.contactInfo")}</h3>
      <div>
        <h4>{t("content.common.lebruderName")}</h4>
      </div>
      <ul className={styled.nonBulletList}>
        <li>
          <PlaceOutlinedIcon />
          <a href="/contactUs">
            {t("content.common.address")}: {t("content.footer.officeAddress")}
          </a>
        </li>
        <li>
          <CallIcon />
          <a href={`tel:${ContactInfo.phone1}`}>
            Hotline: {ContactInfo.phone1}
          </a>
        </li>
        <li>
          <MailOutlineIcon />
          <a href={`mailto:${ContactInfo.infoEmail}`}>
            Email: {ContactInfo.infoEmail}
          </a>
        </li>
      </ul>
    </Col>
  );
  const companyMap = (
    <Col xs={12} sm={6} md={6} lg={3}>
      <h3>{t("content.footer.map")}</h3>
      <div className={styled.map}>
        <a href="/contactUs">
          <img src={require("../assets/images/footerMap.png")} alt="map" />
        </a>
      </div>
    </Col>
  );
  const fbIcon = require("../assets/images/icons/facebook.png");
  const ytIcon = require("../assets/images/icons/youtube.png");
  return (
    <div className={styled.footer}>
      <Row>
        {!isContactPage ? companyAddess : <></>}
        <Col xs={12} sm={6} md={6} lg={isContactPage ? 6 : 3}>
          <h3>{t("content.common.businessFields")}</h3>
          <ul>
            <li>
              <a href="http://">{t("content.common.investment")}</a>
              <hr />
            </li>
            <li>
              <a href="http://">{t("content.common.trading")}</a>
              <hr />
            </li>
            <li>
              <a href="http://">{t("content.common.construction")}</a>
              <hr />
            </li>
          </ul>
        </Col>
        {!isContactPage ? companyMap : <></>}
        <Col xs={12} sm={6} md={6} lg={isContactPage ? 6 : 3}>
          <h3>{t("content.footer.socialNet")}</h3>
          <div className={styled.socialIcons}>
            <div>
              <a href={`https://zalo.me/${strimAllSpace(ContactInfo.phone2)}`}>
                <img src={fbIcon} alt="fb" />
              </a>
            </div>
            <div>
              <a href={`https://zalo.me/${strimAllSpace(ContactInfo.phone2)}`}>
                <img src={ytIcon} alt="fb" />
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={styled.copyright}>
          <div>© 2022 Copyright LE BRUDER JSC</div>
        </Col>
      </Row>
    </div>
  );
};
export default Footer;
