export const projectPost = [
    {
        id: "dongwha",
        image: "https://lebruder.com/wp-content/uploads/2023/05/1-1.jpg",
        titleVi: "Sàn gỗ, tấm ốp tường, ván gỗ MDF Dongwha",
        shortDescVi: "Le Bruder là một trong những đơn vị phân phối sàn gỗ, tấm ốp tường, vẫn gỗ MDF Dongwha tại Việt Nam. Nhà máy sản xuất đặt tại thành phố Sông Công, Thái Nguyên. Các sản phẩm DongWha mang lại vẻ đẹp tự nhiên, sang trọng, bề mặt chống xước, bảo hành đến 20 năm.",
        titleEn: "Laminate flooring, wall panels, MDF Dongwha",
        shortDescEn: "Le Bruder is one of the distributors of wood flooring, and wall panels, still MDF Dongwha in Vietnam. The factory is located in Song Cong city, Thai Nguyen. DongWha products bring natural beauty, luxury, scratch-resistant surfaces, warranty of up to 20 years."

    },
    {
        id: "woodPellet",
        image: "https://lebruder.com/wp-content/uploads/2023/01/viennen.png",
        titleVi: "Viên gỗ nén",
        shortDescVi: "Là một loại nhiên liệu sinh học được sản xuất từ những nguyên liệu tự nhiên như: mùn cưa, gỗ vụn, trấu, thân cây ngô, … Đây đều là những nguyên liệu thừa và được tận dụng lại sau khi sử dụng cây làm gỗ xẻ, đồ gia dụng và các sản phẩm nông nghiệp khác.",
        titleEn: "Wood pellets",
        shortDescEn: "As a biofuel produced from natural materials such as sawdust, wood chips, rice husks, corn stalks, etc. saws, household appliances, and other agricultural products."
    },
    {
        id: "inExteriorPU",
        image: "https://lebruder.com/wp-content/uploads/2023/01/17.jpg",
        titleVi: "Nội - Ngoại thất PU",
        shortDescVi: "Polyurethane (PU) có nguồn gốc từ dầu mỏ, là nhựa Nguyên sinh dạng lỏng. PU là nguyên vật liệu trung gian dùng để sản xuất ra các sản phẩm được các nước phát triển sử dụng trong các ngành công nghiệp và các vật dụng, hàng hóa trong đời sống hằng ngày.",
        titleEn: "Interior - Exterior Polyurethane",
        shortDescEn: "Polyurethane (PU) is derived from petroleum and is a liquid Primary plastic. PU is an intermediate material used to produce products that are used by developed countries in industries and items and goods in daily life."
    },
]