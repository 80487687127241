import { getI18n } from "react-i18next";

const getLocale = () => {
    const lang = getI18n().language;
    let curLang = 'Vi';
    switch (lang) {
        case "en":
            curLang = 'En'
            break;
    }
    return curLang;
}

export {getLocale}