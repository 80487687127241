import { Col, Row } from "react-bootstrap";
import { projectPost } from "../data/productPost";
import LBCard from "../components/ui/LBCard";

const InternalNews = ({
  lang
}: {
  lang?: string
}) => {
  return (
    <div>
      <Row>
        {projectPost.map((project, idx) => {
          const title = `title${lang}` as keyof typeof project;
          const shortDesc = `shortDesc${lang}` as keyof typeof project;
          return (
            <Col key={idx}>
              <LBCard
                title={project[`${title}`]}
                imageUrl={project.image}
                shortDesc={project[`${shortDesc}`]}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
export default InternalNews;
