import { Col, Row } from "react-bootstrap";
import styles from "../assets/style/Header.module.scss";
import LBNavbar from "./LBNavbar";

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerLine}>
      </div>
      <div className={styles.image}>
        <img
          src={require("../assets/images/logo-LeBruder-01.png")}
          alt="logo"
        />
        <h1 className={styles.title}>Le Bruder JSC</h1>
      </div>
      
      <Row>
        <Col>
          <LBNavbar />
        </Col>
      </Row>
    </div>
  );
};

export default Header;
