import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface AppState {
    currentPath?: string
    setCurrentPath: (path: string) => void
}

const useAppStore = create<AppState>()(
    devtools(
        persist(
            (set) => ({
                setCurrentPath: (path) => set((state) => ({ currentPath: path })),
            }),
            {
                name: 'app-storage',
            }
        )
    )
)
export default useAppStore;