import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../styles/News.scss";
import { responsive } from "../../../data/constants";
import { newsPost } from "../../../data/newsPost";
import LBNewsCard from "../../../components/ui/LBNewsCard";

const LBNews = ({
  deviceType,
  lang,
}: {
  deviceType?: string;
  lang?: string;
}) => {
  return (
    <div className="news">
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="transform 1000ms ease-in-out"
        transitionDuration={2000}
        containerClass="carousel-container"
        arrows={false}
        deviceType={deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {newsPost.map((news, idx) => {
          const title = `title${lang}` as keyof typeof news;
          const shortDesc = `shortDesc${lang}` as keyof typeof news;
          return (
            <div className="news-card" key={idx}>
              <LBNewsCard
                target="news"
                id={news.id}
                title={news[`${title}`]}
                imageUrl={news.image}
                shortDesc={news[`${shortDesc}`]}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default LBNews;
