import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsivePartnerLogo, partnerList } from "../../../data/constants";
import "../styles/Partner.scss";
const LBPartnerIntroduce = ({ deviceType }: { deviceType?: string }) => {
  return (
    <div className="partner">
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsivePartnerLogo}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={deviceType !== "mobile" ? true : false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={3000}
        containerClass="carousel-container"
        arrows={false}
        deviceType={deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        showDots={false}
      >
        {partnerList.map((url, idx) => {
          return <PartnerLogo url={url} key={idx} />;
        })}
      </Carousel>
    </div>
  );
};
export default LBPartnerIntroduce;

const PartnerLogo = ({ url, idx }: { url?: string; idx?: string }) => {
  return (
    <div className="partnerLogo">
      <img src={url} alt="" />
    </div>
  );
};
