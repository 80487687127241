export const newsPost = [
    {
        id: "hop-tac-nectatis",
        image: "https://lebruder.com/wp-content/uploads/2023/03/z4224015613830_9206933a7ae974f2465e161d4ed8e6a2.jpg",
        titleVi: "HỢP TÁC LE BRUDER – NECTARIS CUNG CẤP HỆ THỐNG NƯỚC UỐNG TẠI VÒI CHẤT LƯỢNG CHUẨN MỸ VÀ CÁC NƯỚC CHÂU ÂU",
        shortDescVi: "Liên danh Le Bruder – Nectaris mong muốn “mang tới một nguồn nước uống tại vòi, chất lượng tinh khiết, đảm bảo sức khỏe theo tiêu chuẩn Mỹ và các nước Châu Âu”",
        titleEn: "Le Bruder - Nectaris cooperation provides quality standard American and European drinking water systems",
        shortDescEn: "Le Bruder - Nectaris joint venture wishes to “bring a source of drinking water at the tap, pure quality, ensuring health according to American and European standards”"

    },
    {
        id: "biet-thu-vinh-long",
        image: "https://lebruder.com/wp-content/uploads/2023/04/1-scaled-2048x1152.jpg",
        titleVi: "BIỆT THỰ CAO CẤP VĨNH LONG",
        shortDescVi: "Le Bruder E&C hoàn thành hợp đồng thiết kế nội thất – cung cấp vật liệu lát sàn nhà và ốp tường, cầu thang trang trí nội thất nhà ở dân dụng bởi sản phẩm sàn gỗ Dongwha – thương hiệu ván gỗ số 1 Hàn Quốc.",
        titleEn: "VINH LONG LUXURY VILLAGE",
        shortDescEn: "Le Bruder E&C completed the interior design contract - providing floor and wall cladding materials, and stairs for interior decoration of residential houses by Dongwha wood flooring products - the No. 1 wooden board brand in Korea."
    },
    {
        id: "dongwha-thai-nguyen-2",
        image: "https://lebruder.com/wp-content/uploads/2023/03/Picture1-640x400.png",
        titleVi: "THAM QUAN NHÀ MÁY SẢN XUẤT SÀN GỖ CAO CẤP DONGWHA – THÁI NGUYÊN",
        shortDescVi: "Sau chuyến làm việc lần thứ nhất tại nhà máy Dongwha – Thái Nguyên, Công ty Cổ phần Le Bruder tiếp tục có buổi làm việc thứ 2 tại nhà máy. Đi cùng với đoàn công tác có Giám đốc phát triển kinh doanh là ông Ishiguro Haruo (người đại diện của Le Bruder tại thị trường Nhật Bản) và ông Hirotaro Seki – đại diện của công ty Rebirth Nhật Bản (Rebirth là công ty hàng đầu về xuất nhập khẩu thép và các thiết bị công nghệ cao) tham quan nhà máy và trao đổi, định hướng xuất khẩu sản phẩm sàn gỗ Dongwha sang thị trường Nhật Bản.",
        titleEn: "Visiting and working at Dongwha high-class wooden floor factory - Thai Nguyen",
        shortDescEn: "After the first working trip at the Dongwha factory - Thai Nguyen, Le Bruder Joint Stock Company continued to have the second working session at the factory. Accompanying the delegation was the Business Development Manager, Mr. Ishiguro Haruo (the representative of Le Bruder in the Japanese market), and Mr. Hirotaro Seki - the representative of Rebirth Japan (Rebirth is the leading company in Japan)."
    },
    {
        id: "khu-trung-tay-rua",
        image: "https://lebruder.com/wp-content/uploads/2023/01/19.jpg",
        titleVi: "SẢN PHẨM TẨY RỬA, KHỬ TRÙNG VÀ KIỂM SOÁT MÙI",
        shortDescVi: "Sản phẩm của chúng tôi mang lại hiệu quả cao trong việc tẩy rửa, khử trùng nên được sử dụng rộng rãi trong nhiều lĩnh vực, ngành nghề: chăn nuôi, công nghiệp thực phẩm, trường học, bệnh viện, khu vực công cộng",
        titleEn: "CLEANSING, DISINFECTING, AND CONTROLLING PRODUCTS",
        shortDescEn: "Our products bring high efficiency in cleaning and disinfection, so they are widely used in many fields and industries: livestock, food industry, schools, hospitals, and public areas."
    },
    {
        id: "lebruder-soc-trang",
        image: "https://lebruder.com/wp-content/uploads/2023/01/9.jpg",
        titleVi: "CHUYẾN THĂM VÀ LÀM VIỆC CÔNG TY LE BRUDER TẠI SÓC TRĂNG",
        shortDescVi: "Đại diện công ty Le Bruder, ông Nguyễn Mạnh Chiến cám ơn chính quyền đã dành thời gian tiếp đón doanh nghiệp, khẳng định rằng lợi ích của doanh nghiệp luôn đồng hành với trách nhiệm xã hội, ngoài việc đầu tư phát triển khách sạn – du lịch, Le Bruder sẽ kêu gọi các nhà đầu tư, đặc biệt là các nhà đầu tư nước ngoài đến từ Thuỵ Điển, Nhật, Hàn…tham gia vào các dự án công nghiệp nhẹ, công nghiệp chế biến thuỷ hải sản",
        titleEn: "The visit and work of Le Bruder Company in Soc Trang",
        shortDescEn: "The representative of Le Bruder company, Mr. Nguyen Manh Chien thanked the government for taking the time to welcome the business, affirming that the interests of the business always go hand in hand with social responsibility, in addition to investment in hotel development - In tourism, Le Bruder will call on investors, especially foreign investors from Sweden, Japan, Korea... to participate in projects of light industry, seafood processing industry."
    },
    {
        id: "dongwha-thai-nguyen-1",
        image: "https://lebruder.com/wp-content/uploads/2023/01/1-2.jpg",
        titleVi: "THĂM VÀ LÀM VIỆC TẠI NHÀ MÁY SẢN XUẤT SÀN GỖ CAO CẤP DONGWHA – THÁI NGUYÊN",
        shortDescVi: "Trong chuyến thăm quan, đoàn đã được các chuyên gia của Dongwha giới thiệu về lịch sử hình thành tập đoàn Dongwha, các cơ sở của Dongwha trên toàn thế giới. Qua đó là đoàn đã hiểu hơn về sự khác biệt của các sản phẩm Dongwha tại thị trường Việt Nam. Các sản phẩm của Dongwha hướng tới môi trường sống xanh bằng việc sử dụng các loại cốt gỗ sạch, tiêu chuẩn E1, E0 và SE0 (Super Zero) cũng như bề mặt được tráng phủ Nano Silver có khả năng kháng khuẩn.",
        titleEn: "Visiting and working at Dongwha high-class wooden floor factory - Thai Nguyen",
        shortDescEn: "During the visit, the delegation was introduced by Dongwha experts to the history of Dongwha corporation and Dongwha's facilities around the world. Thereby, the delegation understood more about the difference between Dongwha products in the Vietnam market. Dongwha products aim for a green living environment by using clean, E1, E0, and SE0 (Super Zero) wood cores as well as Nano Silver coated surfaces with antibacterial ability."
    },
    {
        id: "pha-thu-thach",
        image: "https://lebruder.com/wp-content/uploads/2023/01/z4004097171960_0d30e5e0d8759b17478f1924d8330569-1-1024x767.jpg",
        titleVi: "“PHÁ THỬ THÁCH, ẮT THÀNH CÔNG”",
        shortDescVi: "Những ngày đầu năm mới 2023, các thành viên Công ty cổ phần Le Bruder đã vượt mọi khó khăn, thử thách và chinh phục thành công Cột mốc số 0, cực Tây – A Pa Chải bằng ý chí mạnh mẽ, cùng nhau đối đầu phá tan những thách thức, vượt lên và chiến thắng bản thân. Bên cạnh đó thể hiện tinh thần đoàn kết, nhất trí, đồng lòng hướng về một mục tiêu chung. Đây là cột mốc đáng nhớ của tập thể Le Bruder, mở ra những chặng đường dài chinh phục các thử thách tiếp theo",
        titleEn: "“Break the challenge, you will succeed”",
        shortDescEn: "In the early days of the new year 2023, the members of Le Bruder Joint Stock Company overcame all difficulties and challenges and successfully conquered Milestone 0, the western pole - A Pa Chai with a strong will, confronting together. Break the challenges, rise above, and conquer yourself. Besides, it shows the spirit of solidarity, consensus, and consensus towards a common goal. This is a memorable milestone for the Le Bruder team, opening a long way to conquer the next challenges"
    },
]