export const projectPost = [
    {
        id: 'hungyen-6storey',
        image: "https://lebruder.com/wp-content/uploads/2023/01/10.png",
        titleVi: "Xây dựng khu nhà ở dịch vụ 6 tầng tại Hưng Yên",
        shortDescVi: "Dự án khu dịch vụ 9 tầng tọa lạc tại thị trấn Bần Yên Nhân, huyện Mỹ Hào, tỉnh Hưng Yên, nằm trong tổ hợp dự án “Cửa hàng kinh doanh xăng dầu và dịch vụ tiện ích” cùng chủ đầu tư là Công ty cổ phần xăng dầu Việt Dũng Hưng Yên.",
        titleEn: "Construction of 6-storey serviced housing in Hưng Yên",
        shortDescEn: "The 9-storey service area project is located in Ban Yen Nhan town, My Hao district, Hung Yen province, located in the project complex “Petroleum shop and utility services” with the investor is the Company. Viet Dung Hung Yen Petroleum Joint Stock Company."
    },
    {
        id: 'lebruder-soctrang',
        image: "https://lebruder.com/wp-content/uploads/2023/01/z3913861224577_6d3658fd403f84e452b6c4d2d116028b-copy-1-scaled.jpg",
        titleVi: "Dự án Tòa nhà phức hợp Le Bruder Sóc Trăng",
        shortDescVi: "Toà nhà liên hợp Le Bruder Sóc Trăng sẽ làm một điểm nhấn lớn không chỉ với thị xã Ngã Năm, nơi mảnh đất ngủ quên với rất nhiều điều kiện phát triển kinh tế – văn hoá – du lịch chưa được khai thác hết",
        titleEn: "Le Bruder Sóc Trăng Complex Building Project",
        shortDescEn: "Le Bruder Soc Trang complex building will make a big highlight not only for Nga Nam town, where the land has fallen asleep with many conditions for economic - cultural - tourism development that have not been fully exploited."
    },
    {
        id: 'hungyen-9storey',
        image: "https://lebruder.com/wp-content/uploads/2023/01/220628-khoi-khach-san-5-tang-view-2-1024x694.jpg",
        titleVi: "Xây dựng khu nhà ở dịch vụ 9 tầng tại Hưng Yên",
        shortDescVi: "Chủ đầu tư là Công ty cổ phần xăng dầu Việt Dũng Hưng Yên. Dự án có quy mô xây dựng trên diện tích 1664 m² gồm 6 tầng với diện tích sàn mỗi tầng khoảng 1570 m², tọa lạc tại xã Trung Hòa, huyện Yên Mỹ, tỉnh Hưng Yên. Thông tin dự án – Chủ đầu tư: Công ty cổ phần Le Bruder",
        titleEn: "Construction of a 9-storey serviced residence in Hưng Yên",
        shortDescEn: "The investor is Viet Dung Hung Yen Petroleum Joint Stock Company. The project has a construction scale of 1664 m² including 6 floors with a floor area of ​​1570 m² on each floor, located in Trung Hòa commune, Yên Mỹ district, Hưng Yên province. Project information – Investor: Le Bruder Joint Stock Company"
    },
]