import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "../../assets/style/LbCard.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const LBCard = ({
  title,
  shortDesc,
  imageUrl,
  target,
  id,
}: {
  id?: string,
  target?: string,
  title?: string,
  shortDesc?: string,
  imageUrl?: string,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gotoDetail = () => {
    navigate(`/${target}/${id}`, {replace: false});
  }
  return (
    <div className="lb-card">
      <Card style={{ width: "18rem" }}>
        <Card.Img variant="top" src={imageUrl} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>
            {shortDesc}
          </Card.Text>
        </Card.Body>
        <div className="bottom">
          <Button variant="primary" onClick={gotoDetail}>{t("content.common.detail")}</Button>
        </div>
      </Card>
    </div>
  );
};
export default LBCard;
