import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import i18n from "./services/i18n";
import { I18nextProvider } from "react-i18next";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import { Container } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const AppContext = React.createContext({});
root.render(
  <AppContext.Provider value={{ lang: "en" }}>
    <I18nextProvider i18n={i18n}>
      <Header />
      <Container>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Container>
      <Footer />
    </I18nextProvider>
  </AppContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
