import "../styles/FieldArea.scss";
const AreaFieldItem = ({ url, title }: { url?: string; title?: string }) => {
  return (
    <div className="field-area">
      <div className="image">
        <img src={url} alt={title} />
      </div>
      <div>
        <p>{title}</p>
      </div>
    </div>
  );
};
export default AreaFieldItem;
