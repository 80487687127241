import { ButtonGroup, DropdownButton } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LanguagePicker from "../components/ui/LanguagePicker";
import { useTranslation } from "react-i18next";
import "../assets/style/LbNavbar.scss";

export default function LBNavbar() {
  const { t } = useTranslation();

  return (
    <div className="lb-navbar">
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            <h6>{t("content.common.home")}</h6>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              navbarScroll
            >
              <Nav.Link href="/aboutUs">
                <h6>{t("content.common.aboutUs")}</h6>
              </Nav.Link>
              <Nav.Link href="/businessFields">
                <h6>{t("content.common.fields")}</h6>
              </Nav.Link>
              <DropdownButton
                className="sub-dropdown-menu"
                as={ButtonGroup}
                key={"down"}
                id={`dropdown-button-drop-${"down"}`}
                drop={"down"}
                variant="link"
                title={t("content.common.products")}
              >
                <NavDropdown
                  title={t("content.common.investment")}
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/product/complexSocTrang">
                    {t("content.navbar.complexSocTrang")}
                  </NavDropdown.Item>

                  <NavDropdown
                    title={t("content.navbar.glampingChain")}
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item href="/product/tuyenQuangGlamping">
                      {t("content.common.tuyenQuangGlamping")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/product/dienBienGlamping">
                      {t("content.common.dienBienGlamping")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/product/caoBangGlamping">
                      {t("content.common.caoBangGlamping")}
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown.Item href="/product/tourismBusiness">
                    {t("content.navbar.tourism")}
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title={t("content.common.trading")}
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown
                    title={t("content.navbar.exportImport")}
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item href="/product/woodPellet">
                      {t("content.common.woodPelet")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/product/spacon">
                      {t("content.common.spacon")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/product/steelSpan">
                      {t("content.common.steelSpan")}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title={t("content.navbar.products")}
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item href="/product/dongwha">
                      {t("content.common.dongwha")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/product/inExteriorPU">
                      {t("content.common.inExteriorPU")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/product/interiorDecor">
                      {t("content.common.interiorDecor")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/product/houseHoldEquipments">
                      {t("content.common.houseHoldEquipments")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/product/constructionMaterials">
                      {t("content.common.buildingMaterials")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown>
                <NavDropdown
                  title={t("content.common.construction")}
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/product/highTech">
                    {t("content.navbar.houseTechnology")}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/product/inExteriorCompletion">
                    {t("content.navbar.inExteriorCompletion")}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/product/templeConstruct">
                    {t("content.navbar.templeConstruction")}
                  </NavDropdown.Item>
                </NavDropdown>
              </DropdownButton>
              <Nav.Link href="/companyMembers">
                <h6>{t("content.common.members")}</h6>
              </Nav.Link>
              <Nav.Link href="/news">
                <h6>{t("content.common.news")}</h6>
              </Nav.Link>
              <Nav.Link href="/contactUs">
                <h6>{t("content.common.contactUs")}</h6>
              </Nav.Link>
            </Nav>
            <Form className="d-flex language-form">
              <LanguagePicker />
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
