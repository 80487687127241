import React, { Suspense } from "react";
import { useParams } from "react-router";
import "../assets/style/GeneralProductStyle.scss"

const ComplexSocTrang = React.lazy(
  () => import("../features/product/components/ComplexSocTrang")
);
const HungYen6Storey = React.lazy(
  () => import("../features/project/components/HungYen6Storey")
);
const HungYen9Storey = React.lazy(
  () => import("../features/project/components/HungYen9Storey")
);
const PageNotFound = React.lazy(
  () => import("../pages/PageNotFound")
);

const Project = () => {
  const params = useParams();
  let content;
  switch (params.id) {
    case "lebruder-soctrang":
      content = <ComplexSocTrang />;
      break;
    case "hungyen-6storey":
      content = <HungYen6Storey />;
      break;
    case "hungyen-9storey":
      content = <HungYen9Storey />;
      break;
    default:
      content = <PageNotFound />;
      break;
  }
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="general-product">
          {content}
        </div>
      </Suspense>
    </>
  );
};
export default Project;
