import { Col, Row } from "react-bootstrap";
import styled from "../assets/style/Home.module.scss";
import ImageSlideShow from "../components/ImageSlideShow";
import { imageList } from "../data/constants";
import LBNews from "../features/home/components/LBNews";
import LBPartnerIntroduce from "../features/home/components/LBPartnerIntroduce";
import { useTranslation } from "react-i18next";
import TypicalProjects from "../features/home/components/TypicalProjects";
import TypicalProducts from "../features/home/components/TypicalProducts";
import BusinessFieldInfo from "../components/ui/BusinessFieldInfo";
import { getLocale } from "../services/lang";
const Home = () => {
  const { t } = useTranslation();
  const curLang = getLocale();
  return (
    <div className={styled.homepage}>
      <div className={styled.scrollBanner}>
        <ImageSlideShow imageList={imageList} />
      </div>
      <div className={styled.product}>
        <div className={styled.sectionTitle}>
          <h3>{t("content.common.businessFields")}</h3>
        </div>
        <hr />
        <BusinessFieldInfo />
      </div>
      <div className={styled.product}>
        <Row>
          <Col md={2}>
            <h3>{t("content.home.typicalProjects")}</h3>
          </Col>
          <Col md={10}>
            <TypicalProjects lang={curLang} />
          </Col>
        </Row>
      </div>
      <div className={styled.product}>
        <Row>
          <Col md={2} className={styled.productHeader1}>
            <h3>{t("content.home.typicalProduct")}</h3>
          </Col>
          <Col md={10}>
            <TypicalProducts lang={curLang} />
          </Col>
          <Col md={2} className={styled.productHeader2}>
            <h3>{t("content.home.typicalProduct")}</h3>
          </Col>
        </Row>
      </div>
      <div className={styled.product}>
        <div className={styled.sectionTitle}>
          <h3>{t("content.home.featureNews")}</h3>
        </div>
        <hr />
        <LBNews lang={curLang} />
      </div>
      <div className={styled.product}>
        <div className={styled.sectionTitle}>
          <h3>{t("content.home.partner")}</h3>
        </div>
        <hr />
        <LBPartnerIntroduce />
      </div>
    </div>
  );
};
export default Home;
