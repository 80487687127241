import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LBPartnerIntroduce from "../features/home/components/LBPartnerIntroduce";
import { AboutUsImages, ContactInfo } from "../data/constants";
import "../../src/assets/style/AboutUs.scss";
import BusinessFieldInfo from "../components/ui/BusinessFieldInfo";
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="about-us">
      <div className="banner">
        <img src={AboutUsImages.banner} alt="banner" />
      </div>
      <Row className="greeting-card section">
        <Col md={6} sm={12}>
          <img src={AboutUsImages.greetingCard} alt="greeting" />
        </Col>
        <Col md={6} sm={12} className="greeting-letter">
          <b>
            <p>{t("content.aboutUs.greeting")}</p>
          </b>
          <div>
            <p>
              {t("content.aboutUs.p1-1")}
              <b>{t("content.common.lebruderName")}</b>
              {t("content.aboutUs.p1-2")}
            </p>
          </div>
          <div>
            <p>
              {t("content.aboutUs.p2-1")}
              <b>{t("content.common.lebruderName")}</b>
              {t("content.aboutUs.p2-2")}
            </p>
          </div>

          <p>{t("content.aboutUs.p3")}</p>
          <div className="letter-closing">
            <b>
              <p>{t("content.aboutUs.p4")}</p>
            </b>
            <p>{t("content.aboutUs.p5")}</p>
            <b>{ContactInfo.director}</b>
          </div>
        </Col>
      </Row>
      <div className="section">
        <div className="title business-field-title">
          <h3>{t("content.common.businessFields")}</h3>
        </div>
        <BusinessFieldInfo />
      </div>
      <div className="section">
        <div className="title">
          <h3>{t("content.aboutUs.orgChart")}</h3>
        </div>
        <div>
          <img src={AboutUsImages.orgChart} alt="orgChart" />
        </div>
      </div>
      <Row className="section">
        <Col>
          <h3>{t("content.aboutUs.vision")}</h3>
          <hr />
          <div>{t("content.aboutUs.visionContent")}</div>
        </Col>
        <Col>
          <img src={AboutUsImages.vision} alt="vision" />
        </Col>
      </Row>
      <Row className="section">
        <Col>
          <img src={AboutUsImages.mission} alt="" />
        </Col>
        <Col>
          <h3>{t("content.aboutUs.mission")}</h3>
          <hr />
          {t("content.aboutUs.missionContent")}
        </Col>
      </Row>
      <Row className="section">
        <Col md={6} sm={12}>
          <h3>{t("content.aboutUs.coreValue")}</h3>
          <hr />
          <h5>{t("content.aboutUs.coreValueH1")}</h5>
          <div>{t("content.aboutUs.coreValueP1")}</div>
          <br />
          <h5>{t("content.aboutUs.coreValueH2")}</h5>
          <div>{t("content.aboutUs.coreValueP2")}</div>
          <br />
          <h5>{t("content.aboutUs.coreValueH3")}</h5>
          <div>{t("content.aboutUs.coreValueP3")}</div>
        </Col>
        <Col md={6} sm={12}>
          <img src={AboutUsImages.coreValue} alt="coreValue" />
        </Col>
      </Row>
      <div className="section">
        <div className="title">
          <h3>{t("content.home.partner")}</h3>
        </div>
        <hr />
        <LBPartnerIntroduce />
      </div>
    </div>
  );
};
export default AboutUs;
