import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import AboutUs from "./pages/AboutUs";
import BusinessFields from "./pages/BusinessFields";
import CompanyMembers from "./pages/CompanyMembers";
import InternalNews from "./pages/InternalNews";
import ContactUs from "./pages/ContactUs";
import Product from "./pages/Product";

import Project from "./pages/Project";
import News from "./pages/News";
import useAppStore from "./store/appStore";

export type Tag = {
  id: string;
  label: string;
};

function App() {
  
  const location = useLocation();
  const setCurrentPath = useAppStore((state) => state.setCurrentPath);
  setCurrentPath(location.pathname);
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/businessFields" element={<BusinessFields />} />
      <Route path="/companyMembers" element={<CompanyMembers />} />
      <Route path="/news" element={<InternalNews />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/product/:id">
        <Route index element={<Product />} />
      </Route>
      <Route path="/project/:id">
        <Route index element={<Project />} />
      </Route>
      <Route path="/news/:id">
        <Route index element={<News />} />
      </Route>
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
