import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../styles/News.scss";
import { responsive } from "../../../data/constants";
import LBCard from "../../../components/ui/LBCard";
import { projectPost } from "../../../data/productPost";

const TypicalProducts = ({
  deviceType,
  lang,
}: {
  deviceType?: string;
  lang?: string;
}) => {
  return (
    <div className="news">
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="transform 1000ms ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        deviceType={deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {projectPost.map((project, idx) => {
          const title = `title${lang}` as keyof typeof project;
          const shortDesc = `shortDesc${lang}` as keyof typeof project;
          return (
            <LBCard
              target="product"
              id={project.id}
              key={idx}
              title={project[`${title}`] }
              imageUrl={project.image}
              shortDesc={project[`${shortDesc}`]}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export default TypicalProducts;

const Article = ({
  title,
  shortDesc,
  imageUrl,
}: {
  title?: string;
  shortDesc?: string;
  imageUrl?: string;
}) => {
  return (
    <div className="news-card">
      <LBCard title={title} imageUrl={imageUrl} shortDesc={shortDesc} />
    </div>
  );
};
