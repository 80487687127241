import { Col, Row } from "react-bootstrap";
import ImageSlideShow from "../components/ImageSlideShow";
import { MemberImages, imageList } from "../data/constants";
import "../assets/style/CompanyMember.scss"
const CompanyMembers = () => {
  return (
    <div className="company-member">
      <div>
        <ImageSlideShow imageList={imageList} />
      </div>
      <Row className="member">
        <Col md={3}>
          <img src={MemberImages.enc} alt="enc" />
        </Col>
        <Col md={9}>
          <h3>CÔNG TY CỔ PHẦN XÂY DỰNG VÀ KỸ THUẬT LE BRUDER</h3>
          <hr />
          <div>
            <b>
              Ngành nghề kinh doanh: Xây dựng – Cung cấp vật liệu chất lượng cao
            </b>
            <br />
            <b> Thông tin mô tả:</b> CÔNG TY CỔ PHẦN XÂY DỰNG VÀ KỸ THUẬT LE
            BRUDER viết tắt là LE BRUDER E&C, được thành lập từ 09/09/2022 từ
            một nhóm đội ngũ kĩ sư trẻ đoàn kết; chính trực. Với lòng yêu nghề
            và sự quyết tâm vươn tới những tri thức mới, chúng tôi sẽ mang tới
            những “chân giá trị” thật nhất tới Quý khách hàng.
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CompanyMembers;
