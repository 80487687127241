import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AreaFieldItem from "../features/home/components/AreaFieldItem";
import { bussinessAreaImgs } from "../data/constants";
import LBPartnerIntroduce from "../features/home/components/LBPartnerIntroduce";
import "../assets/style/BusinessField.scss";
const BusinessFields = () => {
  const { t } = useTranslation();
  return (
    <div className="business-fields">
      <div className="intro">
        <h3>{t("content.common.businessFields")}</h3>
        <hr />
        <Row>
          <Col>
            <a href="#glamping">
              <h5>{t("content.common.investment")}</h5>
              <AreaFieldItem url={bussinessAreaImgs.tourRealEstate} />
            </a>
          </Col>
          <Col>
            <a href="#trading">
              <h5>{t("content.common.trading")}</h5>
              <AreaFieldItem url={bussinessAreaImgs.trading} />
            </a>
          </Col>
        </Row>
        <div className="center-field">
          <a href="#construction">
            <h5>{t("content.common.construction")}</h5>
            <AreaFieldItem url={bussinessAreaImgs.civilConstruction} />
          </a>
        </div>
      </div>
      <div className="article">
        <div id="glamping">
          <h3>{t("content.common.investment")}</h3>
          <hr />
          <p>
            Le Bruder tiên phong trong lĩnh vực phát triển Glamping tại Việt
            Nam; với mong muốn đưa Quý khách hàng trải nghiệm cảnh quan thiên
            nhiên hùng vĩ tươi đẹp cùng hệ thống nội thất; dịch vụ sang trọng;
            tất cả được thiết kế trọn trong những căn nhà Pods hay Dome…
          </p>
          <div>
            <img
              src="https://lebruder.com/wp-content/uploads/2022/10/6_1-Photo-2-3-scaled.jpg"
              alt=""
            />
          </div>
          Ưu điểm Glamping:
          <br />
          – Phù hợp xây dựng trên mọi loại địa hình
          <br />
          – Sử dụng dài hạn với kết cấu vững chắc; có thể tháo dời; di chuyển;
          lắp đặt tại các dự án khác
          <br />
          – Vốn đầu tư ban đầu nhỏ và thi công nhanh chóng Khách hàng hướng tới:
          Khách hàng hướng tới đa dạng; song chủ yếu tập trung tệp khách hàng
          các cặp đôi; hoặc gia đình; Le Bruder mang đến cho bạn, gia đình và
          người thân những khoảnh khắc bên nhau thật ý nghĩa; với những thông
          điệp được chúng tôi truyền tải qua các hoạt động thể thao; gắn kết các
          thành viên trong gia đình; cặp đôi như
          <br />
          – Các hoạt động khu vui chơi trẻ em
          <br />
          – Khu vực nướng ngoài trời BBQ
          <br />– Các hoạt động thể thao ngoài trời: đạp xe; leo núi…
          <p>
            Ngoài ra; chúng tôi đang phát triển các dự án đầu tư du lịch, khách
            sạn; đặc biệt dự án khách sạn phức hợp Ngã Năm Sóc Trăng – Lebruder
            Sóc Trăng với những điểm nhấn kiến trúc văn hoá Champa trong từng
            góc cạnh của khu phức hợp toà nhà. Cùng với sự phát triển đất nước
            và hội nhập quốc tế; với đội ngũ nhân viên luôn luôn học hỏi; không
            ngừng cải tiến; sáng tạo; ứng dụng công nghệ mới; lấy Khách hàng làm
            trọng tâm; chúng tôi sẽ không ngừng cố gắng để mang tới những “chân
            giá trị” thật nhất tới Quý khách hàng.!!!
          </p>
        </div>
        <div id="trading">
          <h3>{t("content.common.trading")}</h3>
          <hr />
          <p>
            Le Bruder tiên phong trong lĩnh vực phát triển Glamping tại Việt
            Nam; với mong muốn đưa Quý khách hàng trải nghiệm cảnh quan thiên
            nhiên hùng vĩ tươi đẹp cùng hệ thống nội thất; dịch vụ sang trọng;
            tất cả được thiết kế trọn trong những căn nhà Pods hay Dome…
          </p>
          <div>
            <img
              src="https://lebruder.com/wp-content/uploads/2023/05/16273d771dfbc2a59bea6-1-scaled-2048x1239.jpg"
              alt=""
            />
          </div>
          <ul>
            Xuất nhập khẩu
            <li>Viên nén gỗ</li>
            <li>Thiết bị, khí ga tiết kiệm điện năng</li>
            <li>Kết cấu thép vượt nhịp</li>
          </ul>
          <ul>Các sản phẩm nội thất ngoại thất</ul>
        </div>
        <div id="construction">
          <h3>{t("content.common.construction")}</h3>
          <hr />
          <p>
            Với chiến lược phát triển bền vững cùng đội ngũ nhân sự chất lượng
            cao, có thương hiệu, uy tín cả trong và ngoài nước trên tinh thần
            đoàn kết, trách nhiệm và tôn trọng, chúng tôi cam kết mang đến cho
            khách hàng “chân giá trị” trong từng sản phẩm, dịch vụ.
            <br />
            <b>Rất hân hạnh được hợp tác và đồng hành cùng quý vị!</b>
          </p>
          <div>
            <img
              src="https://lebruder.com/wp-content/uploads/2022/10/photo-LinhVucKinhDoanh-02.jpg"
              alt=""
            />
          </div>
          <ul>
            Le Bruder E&C
            <li>Tư vấn thiết kế</li>
            <li>Thi công và hoàn thiện</li>
            <li>Cung cấp thiết bị, vật liệu chất lượng cao</li>
          </ul>
        </div>
      </div>
      <div className="partner">
        <h3>{t("content.home.partner")}</h3>
        <hr />
        <LBPartnerIntroduce />
      </div>
    </div>
  );
};
export default BusinessFields;
