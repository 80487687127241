import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownItem from "react-bootstrap/DropdownItem";
import PropTypes from "prop-types";
import { useState } from "react";
import i18n from "../../services/i18n";
import "../../assets/style/LanguagePicker.scss";
import { AppLanguages } from "../../data/constants";

const LanguagePicker = () => {
  const [langId, setLang] = useState("vi");

  const currentLanguage = localStorage.i18nextLng;
  if (currentLanguage !== langId) {
    setLang(currentLanguage)
  }

  const getLabel = (id: string) => {
    return AppLanguages.find((item) => item.id === id)?.label;
  };

  const selectLanguage = (e: any): any => {
    const id = e.target.id;
    i18n.changeLanguage(id);
    setLang(id);
  };
  return (
    <div className="languagePicker">
      <LanguageOutlinedIcon />
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {getLabel(langId)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {AppLanguages.map((item, idx) => {
            return (
              <DropdownItem
                onClick={selectLanguage}
                id={item.id}
                className="item"
                key={idx}
                active={item.id === langId}
              >
                {item.label}
              </DropdownItem>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default LanguagePicker;

LanguagePicker.propTypes = {
  name: PropTypes.string,
};
