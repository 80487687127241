import { Col, Row } from "react-bootstrap";
import LBMap from "../components/ui/LBMap";
import { useEffect, useState } from "react";
import "../assets/style/ContactUs.scss";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import useBearStore from "../store/contactUs";

const ContactUs = () => {
  const [guestName, setGuestName] = useState("");
  const [guestPhone, setGuestPhone] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestMessage, setGuestMesage] = useState("");

  const changeGuestMessage = (e: any) => {
    console.log("changeGuestMessage: ", e);
  };
  const changeGuestName = (e: any) => {
    setGuestName(e.target.value);
  };
  const changeGuestEmail = (e: any) => {
    setGuestEmail(e.target.value);
  };
  const changeGuestPhone = (e: any) => {
    setGuestPhone(e.target.value);
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
  };
  return (
    <div className="contactUs">
      <div>
        <LBMap />
      </div>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <form>
            <div>
              <label>Tên của bạn</label>
              <br />
              <input
                type="text"
                name="guestName"
                value={guestName}
                onChange={changeGuestName}
              />
              <p className="error">* Vui lòng điền tên</p>
              <label>Địa chỉ email</label>
              <br />
              <input
                type="text"
                name="guestEmail"
                value={guestEmail}
                onChange={changeGuestEmail}
              />
              <p className="error">* Vui lòng điền số email</p>
              <label>Số điện thoại</label>
              <br />
              <input
                type="text"
                name="guestPhone"
                value={guestPhone}
                onChange={changeGuestPhone}
              />
              <p className="error">* Vui lòng số điện thoại</p>
              <label>Nội dung</label>
              <br />
              <textarea
                name="guestMessage"
                rows={6}
                value={guestMessage}
                onChange={changeGuestMessage}
              />
            </div>
            <div className="btn-group">
              <input type="submit" onSubmit={onSubmit} value="Gửi yêu cầu" />
            </div>
          </form>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <h3>CÔNG TY CỔ PHẦN LE BRUDER</h3>
          <p>
            <LocationOnOutlinedIcon /> <b> Địa chỉ:</b> BT1B-A305, Khu đô thị Mễ
            Trì Thượng, Phường Mễ Trì, Quận Nam Từ Liêm, Hà Nội, Việt Nam
          </p>
          <br />

          <p>
            <LocalPhoneOutlinedIcon /> <b>Hotline:</b> 0901 23 05 22
          </p>
          <br />
          <p>
            <EmailOutlinedIcon /> <b>Email:</b> info@lebruder.com
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default ContactUs;
