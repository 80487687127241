import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AreaFieldItem from "../../features/home/components/AreaFieldItem";
import { bussinessAreaImgs } from "../../data/constants";
import "../../assets/style/BusinessFieldInfo.scss";
const BusinessFieldInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="business-field-info">
        <Col sm={6} xs={12} md={4}>
          <AreaFieldItem url={bussinessAreaImgs.tourRealEstate} />
          <div className="title">
            <p>{t("content.common.investment")}</p>
          </div>
        </Col>
        <Col sm={6} xs={12} md={4}>
          <AreaFieldItem url={bussinessAreaImgs.trading} />
          <div className="title">
            <p>{t("content.common.trading")}</p>
          </div>
        </Col>
        <Col sm={12} xs={12} md={4}>
          <AreaFieldItem url={bussinessAreaImgs.civilConstruction} />
          <div className="title">
            <p>{t("content.common.construction")}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BusinessFieldInfo;
