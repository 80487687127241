import React, { useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { EAppConstants } from "../../data/constants";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: EAppConstants.lat,
  lng: EAppConstants.lng,
};

const positionIcon = require("../../assets/images/test.png");

const mapInitData = {
  zoom: 18,
};

function LBMap() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: EAppConstants.googleMapKey,
    version: "weekly",
    id:"script-loader",
    libraries:[]
  });

  const [map, setMap] = useState(mapInitData);
  const [isShowMarker, setShowMarker] = useState(true);

  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  // const onUnmount = React.useCallback(function callback(map: any) {
  //   setMap(mapInitData);
  // }, []);

  const onZoomChanged = () => {
    if (map.zoom <= mapInitData.zoom) {
      setShowMarker(true)
    } else {
      setShowMarker(false);
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={mapInitData.zoom}
      onLoad={onLoad}
      // onUnmount={onUnmount}
      onZoomChanged={onZoomChanged}
    >
      {isShowMarker ? (
        <Marker
          title={EAppConstants.companyName}
          icon={{
            url: positionIcon,
          }}
          position={{ lat: EAppConstants.lat, lng: EAppConstants.lng }}
        />
      ) : (
        <></>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(LBMap);
